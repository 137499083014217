import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import OrderShopping from "../components/shopping-carts/order";
import useProduct from "../components/utils/useProduct";

export default function OrderPage({ data }) {
  const { order } = useProduct();
  return (
    <Layout
      title="Ваш заказ"
      description="Трикотажная фабрика IMKON. Элегантность, удобство, качество"
    >
      {order.length > 0 ? (
        <OrderShopping
          mans={data.mans}
          womans={data.womans}
          kids={data.childs}
        />
      ) : (
        <div className="bg-yellow-500 mb-10">
          <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Ваша корзина пуста</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
              Выберите товары и добавьте их в корзину
            </p>
            <Link
              to="/"
              className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-yellow-600 bg-white hover:bg-indigo-50 sm:w-auto"
            >
              На главную
            </Link>
          </div>
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    womans: allSanityWoman {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
    mans: allSanityMan {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
    childs: allSanityChildren {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
