import React, { useState } from "react";
import axios from "axios";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import useProduct from "../utils/useProduct";

export default function OrderShopping({ mans, womans, kids }) {
  const { order, removeFromOrder, setOrder } = useProduct();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitted },
  } = useForm();
  const onSubmit = (data) => {
    if (order.length > 0) {
      const msg = encodeURIComponent(
        "<b>----------Ф.И.О----------\n\n</b>" +
          `${data.fullname}\n\n` +
          "<b>----------Email----------\n\n</b>" +
          `${data.email}\n\n` +
          "<b>----------Заказ----------\n\n</b>" +
          `${order.map((item) => `<b>${item.name}</b> `)}`
      );
      const url = `https://api.telegram.org/bot5052640201:AAEUK6n6ZcGqrAL0nwFvJYLjDjuZ4gfF-8k/sendMessage?chat_id=1965442320&parse_mode=HTML&text=${msg}`;
      axios
        .get(url)
        .then(function (response) {
          // actions.setStatus({ msg: "Ваша заявка принята" });
          // setTimeout(actions.resetForm, 3000);
          setIsSuccessfullySubmitted(response.data.ok);
          reset();
          setTimeout(() => setOrder([]), 3000);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <div className="bg-gray-200 mb-10">
      <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Ваш заказ
        </h1>
        <div className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Товары в вашей корзине
            </h2>

            <ul
              role="list"
              className="border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {order.map((singleOrder, index) => {
                const womanItem = womans.nodes.find(
                  (wom) => wom.id === singleOrder.id
                );

                if (womanItem) {
                  return (
                    <li
                      key={womanItem.id}
                      className="flex py-6 sm:py-10 px-6 sm:px-10 bg-white rounded-lg mb-3"
                    >
                      <div className="flex-shrink-0">
                        <GatsbyImage
                          className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                          image={getImage(womanItem.image.asset)}
                          alt={womanItem.name}
                        />
                      </div>

                      <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                          <div>
                            <div className="flex justify-between">
                              <h3 className="text-sm">{womanItem.name}</h3>
                            </div>
                          </div>

                          <div className="mt-4 sm:mt-0 sm:pr-9">
                            <div className="absolute top-0 right-0">
                              <button
                                type="button"
                                onClick={() => removeFromOrder(index)}
                                className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">Удалить</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="mt-4 flex text-sm text-gray-700 space-x-2">
                          <CheckIcon
                            className="flex-shrink-0 h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span>В корзине</span>
                        </p>
                      </div>
                    </li>
                  );
                }
              })}

              {order.map((singleOrder, index) => {
                const manItem = mans.nodes.find(
                  (wom) => wom.id === singleOrder.id
                );

                if (manItem) {
                  return (
                    <li
                      key={manItem.id}
                      className="flex py-6 sm:py-10 px-6 sm:px-10 bg-white rounded-lg mb-3"
                    >
                      <div className="flex-shrink-0">
                        <GatsbyImage
                          className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                          image={getImage(manItem.image.asset)}
                          alt={manItem.name}
                        />
                      </div>

                      <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                          <div>
                            <div className="flex justify-between">
                              <h3 className="text-sm">{manItem.name}</h3>
                            </div>
                          </div>

                          <div className="mt-4 sm:mt-0 sm:pr-9">
                            <div className="absolute top-0 right-0">
                              <button
                                type="button"
                                onClick={() => removeFromOrder(index)}
                                className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">Удалить</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="mt-4 flex text-sm text-gray-700 space-x-2">
                          <CheckIcon
                            className="flex-shrink-0 h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span>В корзине</span>
                        </p>
                      </div>
                    </li>
                  );
                }
              })}

              {order.map((singleOrder, index) => {
                const childItem = kids.nodes.find(
                  (wom) => wom.id === singleOrder.id
                );

                if (childItem) {
                  return (
                    <li
                      key={childItem.id}
                      className="flex py-6 sm:py-10 px-6 sm:px-10 bg-white rounded-lg mb-3"
                    >
                      <div className="flex-shrink-0">
                        <GatsbyImage
                          className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                          image={getImage(childItem.image.asset)}
                          alt={childItem.name}
                        />
                      </div>

                      <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                          <div>
                            <div className="flex justify-between">
                              <h3 className="text-sm">{childItem.name}</h3>
                            </div>
                          </div>

                          <div className="mt-4 sm:mt-0 sm:pr-9">
                            <div className="absolute top-0 right-0">
                              <button
                                type="button"
                                onClick={() => removeFromOrder(index)}
                                className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">Удалить</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="mt-4 flex text-sm text-gray-700 space-x-2">
                          <CheckIcon
                            className="flex-shrink-0 h-5 w-5 text-green-500"
                            aria-hidden="true"
                          />
                          <span>В корзине</span>
                        </p>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="rounded-lg lg:col-span-5"
          >
            {order.length > 0 && (
              <form
                className="bg-white py-8 px-4 shadow rounded-lg sm:px-10 space-y-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("email", { required: true })}
                      disabled={isSuccessfullySubmitted}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                    />
                    {errors.email && (
                      <p className="text-yellow-600 text-xs">
                        Обязательное поле
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Ф.И.О
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("fullname", { required: true })}
                      disabled={isSuccessfullySubmitted}
                      id="fullname"
                      name="fullname"
                      type="text"
                      autoComplete="fullname"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                    />
                    {errors.fullname && (
                      <p className="text-yellow-600 text-xs">
                        Обязательное поле
                      </p>
                    )}
                  </div>
                </div>
                {isSuccessfullySubmitted && (
                  <div className="text-green-500 text-sm">
                    Сообщение успешно отправлено!
                  </div>
                )}
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Отправить
                  </button>
                </div>
              </form>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
